import {
  LEGAL_FORM,
} from "../../constants/communications";
export default {
  LABELS_LIST: "Liste des étiquettes",
  VIEW_LABEL: "Etiquette",
  ADD_LABEL: "Ajouter une étiquette",
  LABEL_ADDED: "Etiquette ajouté",
  EDIT_LABEL: "Modifier une étiquette",
  LABEL_UPDATED: "Etiquette mise à jour",
  DELETE_THIS_LABEL: "Supprimer cette étiquette ?",
  LABEL_DELETED: "Etiquette supprimée",
  LEGAL_FORM : "Forme juridique",
  CONSTITUVE_LAW: "Loi constitutive",
  NAME : "Nom",
  SLUG : "Code",
  CATEGORY : "Categorie",
  EXCERPT : "Description",
};
