export default {
  CONTACTS_LIST: "Liste des propriétaires",
  ADD_CONTACT: "Ajouter un propriétaire",
  CONTACTS: "Personnes ressources",
  CONTACT_ADDED: "Propriétaire ajouté",
  EDIT_CONTACT: "Modifier un propriétaire",
  CONTACT_UPDATED: "Propriétaire mise à jour",
  DELETE_THIS_CONTACT: "Supprimer ce propriétaire ?",
  CONTACT_DELETED: "Propriétaire supprimé",
  MISTER: "M.",
  MADAM: "Mme",
  CONTACT_TYPE: "Type de propriétaire",
  TITLE_MR: "M.",
  TITLE_MME: "Mme",
  VIEW_CONTACT: "Propriétaire",
  NO_ADDRESS_MESSAGE:
    "Ce propriétaire n'a pas d'adresse. Voulez-vous l'enregistrer avec l'adresse suivante par défaut?",
  ADDRESS_CONFIRMATION: "Confirmation d'adresse",
  PROPERTIES: "Propriétés",
  USE_DEFAULT_ADDRESS: "Utiliser l’adresse par défaut",
  WITHOUT_ADDRESS: "Ne pas mettre d’adresse",
  RETURN_TO_FROM: "Retourner au formulaire",
  SAME_CONTACTS:
    'Un ou plusieurs propriétaires ont déjà été enregistrés avec le prénom {firstname} et le nom {lastname}. S\'agit-il de la même personne ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  ACCOUNT_STATUS: 'Etat de compte',
  PRINT_ACCOUNT_STATUS: 'Imprimer',
  PRINT_ACCOUNT_STATUS_FILE_NAME_OF: "Etat_de_compte",
  SEND_ACCOUNT_STATUS_FILE_NAME_OF_QUESTION: "Etes-vous sùr de vouloir envoyer l'état de compte par mail",
  SEND_ACCOUNT_STATUS_FILE_NAME_OF: "Envoyer par mail",
  ACCOUNT_STATUS_FILE_SENDED: "Un mail est envoyé",
};
