import {
  SOURCE_SOURCE_BANK_DRAFT,
  SOURCE_CERTIFIED_CHECK,
  SOURCE_POST_ORDER,
  SOURCE_CASH,
  SOURCE_BANK_TRANSFER,
  SOURCE_NORMAL_CHECK,
  SOURCE_FIDEOCOMMIS_CHECK,
  SOURCE_PAYMENT_SOURCE_NORMAL_CHECK,
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUS_CANCELED,
} from "../../constants/propertiesPayment";
export default {
  PROPERTIES_PAYMENTS_LIST: "Liste des paiements",
  ADD_PROPERTIES_PAYMENT: "Ajouter un paiement",
  PROPERTIES_PAYMENT_ADDED: "Paiement ajouté",
  EDIT_PROPERTIES_PAYMENT: "Modifier un paiement",
  PROPERTIES_PAYMENT_UPDATED: "Paiement mise à jour",
  PROPERTY: "Propriété",
  AMOUNT: "Montant",
  SOURCE: "Méthode de transaction",
  DATE: "Date de paiement",
  ATTENTION : "à l'attention de",
  EMAIL: "Email",
  EXCERPT: "Note",
  VIEW_PROPERTIES_PAYMENT: "Paiement",
  DELETE_THIS_PROPERTIES_PAYMENT: "Supprimer ce paiement ?",
  PROPERTIES_PAYMENT_DELETED: "Paiement supprimé",
  PAYMENT_SOURCE_BANK_DRAFT: "Traite bancaire",
  PAYMENT_SOURCE_CERTIFIED_CHECK: "Chèque certifié",
  PAYMENT_SOURCE_POST_ORDER: "Mandat poste",
  PAYMENT_SOURCE_CASH: "Argent comptant",
  PAYMENT_SOURCE_BANK_TRANSFER: "Transfert bancaire",
  PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normal",
  PAYMENT_SOURCE_FIDEOCOMMIS_CHECK: "Chèque fidéocommis",
  PAYMENT_SOURCE_PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normal",
  PAYMENT_STATUS_DRAFT: "Brouillon",
  PAYMENT_STATUS_COMPLETED: "Completée",
  PAYMENT_STATUS_CANCELED: "Annulée",
  PAYMENT_AMOUNT_GRATER_TO_SUM: "Le montant saisi est supérieur au total du montant des propriétés : ",
  PAYMENT_REFUND_TEXT: "ou de l'ajouter dans le montant de la dérinière propriété pour qu'il sera rembourser après",
  PAYMENT_ANOTHER_PROPERTY_TEXT: "Vous pouvez soit payer une autre propriété",
  PAYMENT_REFUND_BTN: "Faire un rembousement",
  PAYMENT_ANOTHER_PROPERTY_BTN: "Payer une autre propriété",
  REFUND_PROPERTY: "Remboursement d'une propriété",
  SELECT_PAYMENT_METHOD: "Veuillez séléctionner la méthode de paiement",
  PAYMENT_REFUND_LAST_PROPERTY_TEXT: "Ce montant sera rembourser au compte de la propriété",
  REST_TO_PAY: "Reste à payer",
  EMPTY_ATTENTION: "Le champ attention est obligatoire",
  EMPTY_AMOUNT: "Le champ montant est obligatoire",
  EMPTY_DATE: "Le champ date est obligatoire",
  EMPTY_EMAIL: "Le champ Email est obligatoire",
  SEND_MAIL: "Envoyer le reçu de paiement par mail",
  RECEIPT: "Reçu",
  SEND_MAIL: "Envoyer le reçu de paiement par mail",
  SEND_MAIL_RECEIPT_QUESTION: "Etes-vous sùr de vouloir envoyer le reçu par mail",
  SEND_MAIL_RECEIPT: "Envoyer par mail",
  SENDED_RECEIPT: "Un mail est envoyé",
  PRINT_RECEIPT: "Imprimer",
  TOTAL_DU: "Total dû",
};
