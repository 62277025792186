import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/organizations`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/organizations/${id}?include=owner,reseller`, options)
    .then((response) => {
      let organization = jsona.deserialize(response.data);
      delete organization.links;
      return organization;
    });
}

function add(organization) {
  const payload = jsona.serialize({
    stuff: organization,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/organizations?include=owner,reseller`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(organization) {
  const payload = jsona.serialize({
    stuff: organization,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/organizations/${organization.id}?include=owner,reseller`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/organizations/${id}`, options);
}

function generateSubscriptionInvoice(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/organizations/${id}/generate_subscription_invoice`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function getTemplates(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/organizations/${id}/templates`, options).then((response) => {
    return {
      templates: response.data,
    };
  });
}

function saveTemplates(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/organizations/${id}/templates`,
    data,
    options
  ).then((response) => {
    return response;
  });
}

function getExamplePreview(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    responseType: "blob",
  };

  return axios.post(`${url}/organizations/${data.id}/templates-preview`,
    data,
    options
  ).then((response) => {
    return response;
  });
}


export default {
  list,
  get,
  add,
  update,
  destroy,
  getTemplates,
  saveTemplates,
  generateSubscriptionInvoice,
  getExamplePreview
};
