import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function print(id) {
  const options = {
    headers: {},
    responseType: "blob",
  };
  return axios.get(
    `${url}/properties-payments/${id}/print-receipt`,
    options
  );
}

function printOne(paymentId, propertyId) {
  const options = {
    headers: {},
    responseType: "blob",
  };
  return axios.get(
    `${url}/properties-payments/${paymentId}/${propertyId}/print-one-receipt`,
    options
  );
}

function list(params) {
  let options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/properties-payments`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/properties-payments/${id}?include=organization,propertiesPaymentsProperties,propertiesPaymentsProperties.property`,
      options
    )
    .then((response) => {
      let propertiesPayment = jsona.deserialize(response.data);
      delete propertiesPayment.links;
      return propertiesPayment;
    });
}

function add(propertiesPayment) {
  const payload = jsona.serialize({
    stuff: propertiesPayment,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/properties-payments`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(propertiesPayment) {
  const payload = jsona.serialize({
    stuff: propertiesPayment,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };
  return axios
    .patch(`${url}/properties-payments/${propertiesPayment.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/properties-payments/${id}`, options);
}

function sendMailReceipt(data) {

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/properties-payments/send-mail-receipt`, data, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  print,
  printOne,
  list,
  get,
  add,
  update,
  destroy,
  sendMailReceipt,
};
